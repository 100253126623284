<template>
  <v-row>
    <v-col cols="12" sm="12" md="12" class="mx-2">
      <span class="body-1">Imagem do IPTU</span>
      <input-file
        label="Clique no botão para selecionar"
        label-send="O IPTU foi enviado com sucesso"
        hint="Pode ser uma foto, só preste atenção e verifique se o número do contribuinte impresso no boleto está legível."
        v-model="company.idFileIptu"
        ref="companyIdFileIptu"
        required
        :loading="loadingIptu"
        :rules="rules.iptu"
        @change="saveIPTU"
      ></input-file>
    </v-col>

    <v-col cols="12" sm="12" md="12" v-if="!!company.hasPartner">
      <v-switch
        label="Já possui contrato social?"
        @change="saveHasSocialContract"
        v-model="company.hasSocialContract"
      ></v-switch>
      <p
        class="body-1"
        v-show="!company.hasSocialContract && !!company.hasPartner"
      >
        {{ hasSocialContractHint }}
      </p>
      <input-file
        v-show="!!company.hasSocialContract && !!company.hasPartner"
        ref="contratoSocial"
        label="Enviar o contrato Social"
        label-send="Arquivo enviado com Sucesso!"
        v-model="company.idContratoSocial"
        :loading="loadingContratoSocial"
        :rules="rules.socialContract"
        @change="saveContratoSocial"
        required
      ></input-file>
    </v-col>
  </v-row>
</template>
<script>
import InputFile from '@src/components/widget/InputFile.vue'

import { companyMethods, companyComputed } from '@state/helpers'
export default {
  name: 'company-opening',
  components: {
    InputFile,
  },
  mounted() {},
  data() {
    return {
      rules: {
        iptu: [
          (v) => {
            return !!v || 'Selecione um arquivo de IPTU'
          },
        ],
        socialContract: [
          (v) => {
            return (
              !!this.company.hasSocialContract ||
              !!this.company.hasPartner ||
              'Selecione o contrato social'
            )
          },
        ],
      },
    }
  },
  computed: {
    ...companyComputed,
    hasSocialContractHint() {
      let hint =
        'Caso não possua, enviaremos o contrato social padrão nos modelos da Junta Comercial ou Cartório sem custo.'
      return this.company.hasSocialContract ? '' : hint
    },
  },
  methods: {
    ...companyMethods,
    async saveIPTU(b64File) {
      await this.$store.dispatch('taxonomy/saveCompanyFileIptu', b64File)
    },
    async saveContratoSocial(file) {
      const newFile = await this.$store.dispatch(
        'taxonomy/saveCompanyContratoSocialConstituicao',
        file
      )
    },
    saveHasSocialContract(v) {
      this.sendToApi('hasSocialContract', v)
    },
    sendToApi(fild, value) {
      let data = { [fild]: value }
      this.$store.dispatch('company/updateCompany', data)
    },
  },
}
</script>
